import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const PrivacyPolicyPage = () => {
    return (
        <div className="flex flex-col min-h-screen bg-background text-text">
            <Header />
            <div className="flex-grow container mx-auto px-6 py-12">
                <h1 className="text-5xl font-bold mb-6 text-center">Privacy Policy</h1>
                <div className="text-left max-w-4xl mx-auto">
                    <p className="mb-4">Effective date: April 21, 2024</p>
                    <p className="mb-4">
                        Lenny Industries ("us", "we", or "our") operates the www.lennyindustries.net website
                        (hereinafter referred to as the "Service").
                    </p>
                    <p className="mb-4">
                        This page informs you of our policies regarding the collection, use, and disclosure of personal
                        data when you use our Service and the choices you have associated with that data.
                    </p>

                    <h2 className="text-3xl font-bold mb-4">Information Collection And Use</h2>
                    <p className="mb-4">
                        We collect several different types of information for various purposes to provide and improve
                        our Service to you.
                    </p>

                    <h2 className="text-3xl font-bold mb-4">Types of Data Collected</h2>
                    <h3 className="text-2xl font-bold mb-2">Personal Data</h3>
                    <p className="mb-4">
                        While using our Service, we may ask you to provide us with certain personally identifiable
                        information that can be used to contact or identify you ("Personal Data"). Personally
                        identifiable information may include, but is not limited to:
                        <ul className="list-disc list-inside mb-4">
                            <li>Email address</li>
                            <li>First name and last name</li>
                            <li>Phone number</li>
                            <li>Address, State, Province, ZIP/Postal code, City</li>
                            <li>Cookies and Usage Data</li>
                        </ul>
                    </p>

                    <h2 className="text-3xl font-bold mb-4">Use of Data</h2>
                    <p className="mb-4">
                        Lenny Industries uses the collected data for various purposes:
                        <ul className="list-disc list-inside mb-4">
                            <li>To provide and maintain our Service</li>
                            <li>To notify you about changes to our Service</li>
                            <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
                            <li>To provide customer support</li>
                            <li>To gather analysis or valuable information so that we can improve our Service</li>
                            <li>To monitor the usage of our Service</li>
                            <li>To detect, prevent and address technical issues</li>
                        </ul>
                    </p>

                    <h2 className="text-3xl font-bold mb-4">Changes to This Privacy Policy</h2>
                    <p className="mb-4">
                        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                    </p>
                    <p className="mb-4">
                        We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.
                    </p>
                    <p className="mb-4">
                        You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PrivacyPolicyPage;