import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

const faqData = [
    {
        question: "What services do you offer?",
        answer: "Almost everything 🙏, Contact me with your project in mind and we can chat about it!"
    },
    {
        question: "What payment methods do you accept?",
        answer: "Bank transfer, Cards, Paypal, Crypto, please just ask!"
    },
    {
        question: "How do I start a project with you?",
        answer: "To start a project, simply contact me, and I will get in touch with you and we can discuss it"
    }
];

const FAQPage = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = index => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="flex flex-col min-h-screen bg-background text-text">
            <Header />
            <main className="flex-grow container mx-auto px-6 py-12">
                <h1 className="text-5xl font-bold mb-6 text-center">FAQ</h1>
                <p className="mb-4 text-lg">
                    Majority of questions are answered here, Otherwise Please contact me <Link to="/contact" className="text-blue-500 hover:text-blue-700">HERE</Link>.
                </p>
                <div className="space-y-4">
                    {faqData.map((faq, index) => (
                        <div key={index} className="bg-secondary p-4 rounded-lg text-white shadow-lg">
                            <h2 className="text-xl font-bold cursor-pointer" onClick={() => toggleFAQ(index)}>
                                {faq.question}
                            </h2>
                            {activeIndex === index && (
                                <p className="mt-2 text-lg">{faq.answer}</p>
                            )}
                        </div>
                    ))}
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default FAQPage;