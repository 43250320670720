import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="py-4 px-6 bg-background text-text shadow-md rounded-lg flex flex-col items-center text-center">
            <span>&copy; {new Date().getFullYear()} Lenny Industries. All rights reserved.</span>
            <div className="flex flex-row justify-center mt-2">
                <Link to="/terms" className="text-xl hover:text-primary" style={{ color: '#8aa07b', textDecoration: 'none' }}>Terms of Service</Link>
                <span className="text-xl mx-2" style={{ color: '#8aa07b' }}>|</span> {/* Vertical bar as a separator */}
                <Link to="/privacy" className="text-xl hover:text-primary" style={{ color: '#8aa07b', textDecoration: 'none' }}>Privacy Policy</Link>
            </div>
        </footer>
    );
};

export default Footer;
