import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const TermsPage = () => {
    return (
        <div className="flex flex-col min-h-screen bg-background text-text">
            <Header />
            <main className="flex-grow container mx-auto px-6 py-12 text-center">
                <h1 className="text-5xl font-bold mb-6">Terms of Service</h1>
                <div className="text-left max-w-4xl mx-auto">
                    <h2 className="text-3xl font-bold mb-4">1. Introduction</h2>
                    <p className="mb-4">
                        Welcome to Lenny Industries! These terms of service outline the rules and regulations for the use of our website, located at any subdomain of lennyindustries.net or lenny.ie.
                    </p>

                    <h2 className="text-3xl font-bold mb-4">2. Intellectual Property Rights</h2>
                    <p className="mb-4">
                        Other than the content you own, under these Terms, Lenny Industries and/or its licensors own all the intellectual property rights and materials contained in this website.
                    </p>

                    <h2 className="text-3xl font-bold mb-4">3. Restrictions</h2>
                    <p className="mb-4">
                        You are specifically restricted from all of the following:
                        <ul className="list-disc list-inside">
                            <li>Publishing any website material in any other media without proper citation</li>
                            <li>Selling, sublicensing and/or otherwise commercializing any website material</li>
                            <li>Using this website in any way that is damaging, or that could be damaging to this website</li>
                            <li>Using this website in any way that impacts user access to this website</li>
                        </ul>
                    </p>

                    <h2 className="text-3xl font-bold mb-4">4. Your Content</h2>
                    <p className="mb-4">
                        In these Terms of Service, "Your Content" shall mean any audio, video, text, images, or other material you choose to display on this website. By displaying Your Content, you grant Lenny Industries a non-exclusive, worldwide irrevocable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.
                    </p>

                    <h2 className="text-3xl font-bold mb-4">5. No warranties</h2>
                    <p className="mb-4">
                        This website is provided "as is," with all faults, and Lenny Industries expresses no representations or warranties, of any kind related to this website or the materials contained on this website.
                    </p>

                    <h2 className="text-3xl font-bold mb-4">6. Limitation of liability</h2>
                    <p className="mb-4">
                        In no event shall Lenny Industries, nor any of its officers, directors, and employees, be held liable for anything arising out of or in any way connected with your use of this website whether such liability is under contract. Lenny Industries, including its officers, directors, and employees shall not be held liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this website.
                    </p>

                    <h2 className="text-3xl font-bold mb-4">7. Severability</h2>
                    <p className="mb-4">
                        If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.
                    </p>

                    <h2 className="text-3xl font-bold mb-4">8. Variation of Terms</h2>
                    <p className="mb-4">
                        Lenny Industries is permitted to revise these Terms at any time as it sees fit, and by using this website you are expected to review these Terms on a regular basis.
                    </p>

                    <h2 className="text-3xl font-bold mb-4">9. Assignment</h2>
                    <p className="mb-4">
                        Lenny Industries is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.
                    </p>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default TermsPage;