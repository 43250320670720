import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const HomePage = () => {
    return (
        <div className="flex flex-col min-h-screen bg-background text-text">
            <Header />
            <main className="flex-grow container mx-auto px-6 py-12 text-center">
                <h1 className="text-5xl font-bold mb-4" style={{ color: '#bfa7a8' }}>Welcome to Lenny Industries</h1>
                <p className="text-xl mb-8">
                    High quality, custom and simple IT projects and solutions
                </p>
                <section className="mt-12">
                    <h2 className="text-4xl font-bold mb-6">High-Value Expertise and Skills</h2>
                    <div className="flex flex-wrap justify-center gap-4">
                        <div className="bg-secondary p-4 rounded-lg text-white shadow-lg w-full md:w-1/3">
                            <h3 className="text-2xl font-bold mb-2">Full Stack Development</h3>
                            <ul className="list-disc list-inside space-y-1">
                                <li>Node.js for backend services</li>
                                <li>Next.js for React-based applications</li>
                                <li>TailwindCSS for modern UI design</li>
                                <li>MongoDB for NoSQL database solutions</li>
                            </ul>
                        </div>
                        <div className="bg-secondary p-4 rounded-lg text-white shadow-lg w-full md:w-1/3">
                            <h3 className="text-2xl font-bold mb-2">Cloud Solutions & Deployment</h3>
                            <ul className="list-disc list-inside space-y-1">
                                <li>Hetzner for cost-effective server solutions</li>
                                <li>Vercel for frontend deployment</li>
                                <li>Cloudflare for CDN and security</li>
                                <li>AWS for scalable cloud infrastructure</li>
                            </ul>
                        </div>
                        <div className="bg-secondary p-4 rounded-lg text-white shadow-lg w-full md:w-1/3">
                            <h3 className="text-2xl font-bold mb-2">Cybersecurity Implementation</h3>
                            <ul className="list-disc list-inside space-y-1">
                                <li>Firewalls to safeguard network boundaries</li>
                                <li>Advanced authentication protocols</li>
                                <li>Strategies for combating cyber threats</li>
                                <li>Encryption and data obfuscation techniques</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="mt-12">
                    <h2 className="text-4xl font-bold mb-6 text-center">Featured Projects</h2>
                    <div className="flex flex-wrap justify-center items-stretch gap-4">
                        <a href="https://ratterscanner.com" className="bg-secondary p-4 rounded-lg text-white shadow-lg w-full md:w-1/3 flex flex-col space-y-4 justify-between hover:bg-primary transition-colors duration-300">
                            <h3 className="text-2xl font-bold">Ratter Scanner</h3>
                            <p>A groundbreaking anti-malware initiative, Ratter Scanner significantly enhances security for Hypixel Skyblock players. By scanning over 10,000 files, this platform has dramatically reduced the success of malicious files, keeping thousands of users safe from theft. The success of Ratter Scanner is a testament to the robust community support and engagement it has garnered.</p>
                        </a>
                        <a href="https://lenny.ie" className="bg-secondary p-4 rounded-lg text-white shadow-lg w-full md:w-1/3 flex flex-col space-y-4 justify-between hover:bg-primary transition-colors duration-300">
                            <h3 className="text-2xl font-bold">My Own Personal Reputation and Brand</h3>
                            <p>I've developed applications and Discord bots, ascending to a premier position within the community. As a trusted middleman, I've facilitated transactions worth hundreds of thousands of dollars, establishing a highly reputable and recognizable brand known for reliability and innovation.</p>
                        </a>
                    </div>
                </section>
                <section className="mt-12">
                    <h2 className="text-4xl font-bold mb-6 text-center">Got a question?</h2>
                    <p className="mb-4 text-lg">If your question is not answered in the FAQ, feel free to contact me about anything!</p>
                    <div className="mx-auto bg-secondary p-4 rounded-lg text-white shadow-lg w-full md:w-2/3 lg:w-1/3 flex flex-row justify-center items-center gap-4">
                        <a href="/faq" className="bg-primary p-4 rounded-md text-white shadow-lg flex-1 text-center">
                            <h3 className="text-2xl font-bold">FAQ</h3>
                        </a>
                        <a href="/contact" className="bg-primary p-4 rounded-md text-white shadow-lg flex-1 text-center">
                            <h3 className="text-2xl font-bold">Contact Me</h3>
                        </a>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
};

export default HomePage;
