import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const ContactPage = () => {
    return (
        <div className="flex flex-col min-h-screen bg-background text-text">
            <Header />
            <main className="flex-grow container mx-auto px-6 py-12 text-center">
                <h1 className="text-5xl font-bold mb-6">Contact Us</h1>
                <p className="text-xl mb-8">If you have any questions or need support, feel free to email me directly.</p>
                <a href="mailto:me@lennyindustries.net"
                   className="text-lg bg-primary hover:bg-secondary text-white py-3 px-6 rounded-md font-bold transition-colors duration-300">
                    Email me@lennyindustries.net
                </a>
            </main>
            <Footer />
        </div>
    );
};

export default ContactPage;
