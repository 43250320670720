import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <header className="py-2 px-6 flex justify-between items-center bg-background text-text shadow-md rounded-lg">
            <Link to="/" style={{ textDecoration: 'none' }}>
                <img src="/logo.png" alt="Lenny Industries Logo" style={{ height: '60px' }} />
            </Link>
            <nav className={`md:flex ${isOpen ? 'flex' : 'hidden'} flex-col md:flex-row space-y-2 md:space-y-0 space-x-0 md:space-x-6`}>
                <Link to="/contact" className="text-xl hover:text-primary" style={{ color: '#8aa07b', textDecoration: 'none' }}>Contact</Link>
                <Link to="/faq" className="text-xl hover:text-primary" style={{ color: '#8aa07b', textDecoration: 'none' }}>FAQ</Link>
            </nav>
            <button onClick={toggleMenu} className="md:hidden text-xl" style={{ color: '#8aa07b', background: 'none', border: 'none', cursor: 'pointer' }}>
                ☰
            </button>
        </header>
    );
};

export default Header;
