import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

const NotFoundPage = () => {
    const navigate = useNavigate();

    const goBackOrHome = () => {
        if (window.history.length > 2) {
            navigate(-1);
        } else {
            navigate('/');
        }
    };

    return (
        <div className="flex flex-col min-h-screen bg-background text-text">
            <Header />

            <main className="container mx-auto px-8 py-12 flex-grow">
                <section className="text-center">
                    <h2 className="text-5xl font-bold mb-8" style={{ color: '#bfa7a8' }}>404 - Page Not Found</h2>
                    <p className="text-xl mb-10">The page you're looking for does not exist.</p>
                    <button
                        onClick={goBackOrHome}
                        style={{ backgroundColor: '#8aa07b', color: '#f5f5f5', padding: '10px 20px', fontSize: '16px', borderRadius: '5px', cursor: 'pointer' }}
                    >
                        Go Back
                    </button>
                </section>
            </main>

            <Footer />
        </div>
    );
};

export default NotFoundPage;